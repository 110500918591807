import React from "react";
import Layout from "../components/Layout/Layout";

const NotFound = () => {
  return (
    <Layout>
      <h1>404</h1>
    </Layout>
  );
};

export default NotFound;
